<template>
  <div class="container">
    <h2
      class="mb-0"
      :class="{
        'text-center': component?.layout === 'center',
        'text-end': component?.layout === 'right',
      }"
    >
      {{ component.title }}
    </h2>
    <p
      v-if="component.subText"
      class="subtext"
      :class="{
        'text-center': component?.layout === 'center',
        'text-end': component?.layout === 'right',
      }"
    >
      {{ component.subText }}
    </p>
    <div class="mt-8 text-center">
      <strapi-image
        :image-class="`${fullWidth ? 'w-100' : ''}`"
        :alt="component.title"
        :image="component.image"
        fluid
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiLocationsMap } from '~/apollo/types/types';
import StrapiImage from './StrapiImage.vue';

export default defineComponent({
  name: 'StrapiProductLocationsMap',
  components: {
    StrapiImage,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    component: {
      type: Object as PropType<StrapiLocationsMap>,
      required: true,
    },
  },
});
</script>
